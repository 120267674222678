import { Controller } from '@hotwired/stimulus';
import * as toastr from 'toastr';

export default class extends Controller {
  static values = {
    recordDomId: String,
    recordFormAction: String,
    errorField: String,
    errorFieldMessage: String,
    errorMessage: String,
    errorType: String
  };

  connect() {
    this.set_toastr_options();
    this.element[this.identifier] = this;
    if (this.errorMessageValue) {
      this.showAlert();
    }
    if (this.errorFieldValue) {
      this.showFieldAlert();
    }
  }

  set_toastr_options() {
    toastr.options.progressBar = true;
    toastr.options.timeOut = 10000; // How long the toast will display without user interaction
    toastr.options.extendedTimeOut = 2000; // How long the toast will display after a user hovers over it
  }

  pushAlert(error_type, message) {
    this.errorTypeValue = error_type;
    this.errorMessageValue = message;
    this.showAlert();
  }

  showAlert() {
    // Switch case notice || alert || error || warning || success || info
    // Combine alert and error
    // Combine notice and success
    // And show a toastr notification
    switch (this.errorTypeValue) {
      case 'notice':
      case 'success':
        toastr.success(this.errorMessageValue, 'Notice');
        break;
      case 'alert':
      case 'error':
        toastr.error(this.errorMessageValue, 'Alert');
        break;
      case 'warning':
        toastr.warning(this.errorMessageValue, 'Warning');
        break;
      case 'info':
        toastr.info(this.errorMessageValue, 'Info');
        break;
      default:
        toastr.info(this.errorMessageValue);
    }
  }

  getField() {
    // Look for the the element with name this.errorFieldValue and set data-error-message attribute to this.errorFieldMessageValue
    let field = document.querySelector(`[name='${this.errorFieldValue}']`);
    if (this.recordFormActionValue) {
      // Look up the form with this action and then find the field within the form
      let form = document.querySelector(`form[action='${this.recordFormActionValue}']`);
      if (form) {
        field = form.querySelector(`[name='${this.errorFieldValue}']`);
      }
    }
    return field;
  }

  showFieldAlert() {
    // Look for the the element with name this.errorFieldValue and set data-error-message attribute to this.errorFieldMessageValue
    let field = this.getField();
    let record = document.getElementById(this.recordDomIdValue);
    if (field) {
      field.setAttribute('data-error-message', this.errorFieldMessageValue);
      field.classList.add('error');
    }
    if (record) {
      record.scrollIntoView({ behavior: 'smooth', block: 'center' });
      record.setAttribute('data-error-message', this.errorFieldMessageValue);
      record.classList.add('has-error');
    }
  }

  disconnect() {
    let field = this.getField();
    if (field) {
      field.removeAttribute('data-error-message');
      field.classList.remove('error');
    }
    let record = document.getElementById(this.recordDomIdValue);
    if (record) {
      record.removeAttribute('data-error-message');
      record.classList.remove('has-error');
    }
  }
}
