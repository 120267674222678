import { Controller } from '@hotwired/stimulus';
// import 'jquery-ui/ui/widgets/datepicker';

export default class extends Controller {
  static targets = ['fromField', 'toField'];
  connect() {
    let from_field = $(this.fromFieldTarget);
    let to_field = $(this.toFieldTarget);
    from_field.datepicker({
      dateFormat: 'dd-mm-yy',
      changeMonth: true,
      onSelect: function () {
        // fire a native event to trigger data-action="change->" kind-of events
        let event = new Event('change', { bubbles: true });
        this.dispatchEvent(event);
      },
      onClose: function (selectedDate) {
        to_field.datepicker('option', 'minDate', selectedDate);
      }
    });

    to_field.datepicker({
      dateFormat: 'dd-mm-yy',
      changeMonth: true,
      onSelect: function () {
        // fire a native event to trigger data-action="change->" kind-of events
        let event = new Event('change', { bubbles: true }); // fire a native event
        this.dispatchEvent(event);
      },
      onClose: function (selectedDate) {
        from_field.datepicker('option', 'maxDate', selectedDate);
      }
    });
  }

  reset() {
    $(this.fromFieldTarget).val(null).trigger('change');
    $(this.toFieldTarget).val(null).trigger('change');
  }

  disconnect() {
    $(this.fromFieldTarget).datepicker('destroy');
    $(this.toFieldTarget).datepicker('destroy');
  }
}
