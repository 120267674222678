import { Controller } from '@hotwired/stimulus';
// import $ from 'jquery';

export default class extends Controller {
  static values = {
    href: String
  };

  // If an element has the data-href attribute, clicking it will redirect the user to the given path.
  // If a sub-element has the .prevent-click class, it will prevent this trigger to fire.
  // Middle mouse and ctrl-left click will open the link in a new tab.
  connect() {}

  openLink(event) {
    let url = this.hrefValue;
    // if this.element doesn't have the class disabled or the attribute disabled
    if (!this.element.classList.contains('disabled') && !this.element.hasAttribute('disabled')) {
      if ($(event.target).parents('.prevent-click').length || event.target.href) {
        // Do nothing. Let the element fire it's own event.
      } else {
        switch (event.which) {
          // Left Click.
          case 1:
            // By way of example, I've added Ctrl+Click Modifiers.
            if (event.ctrlKey) {
              // Ctrl+LeftClick behaviour.
              window.open(url, '_blank');
            } else {
              // Standard LeftClick behaviour.
              window.location.href = url;
            }
            break;

          // Middle click.
          case 2:
            window.open(url, '_blank');
            break;

          // Default behaviour for right click.
          case 3:
            return;
        }
      }
    }
  }
}
